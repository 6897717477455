

.DesktopNewsMenu {
  padding-top: 10px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  width: 220px;
  margin-right: 20px;


  ul {
    list-style-type: none;
    padding-left: 10px;
  }

  div.menu-line {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 0px;
    border-bottom: 1.3px solid #bbb;
  }

  .menu-stream {
    height: 42.3px;

    border-bottom: 1.5px solid transparent;
    &.Highlight {
      border-bottom: 1.5px solid #5686a9;
    }

    &.search, &.query {
      margin-bottom: 20px !important;
    }

    cursor:pointer;
    color: #aaa;
    padding-bottom: 0px;
    margin-bottom: 0px;

    &.active {
      color: #5686a9;
      background-color: #eee;
    }

    &:hover {
       color: #5686a9;
       background-color: #f6f6f6;

       .menu-icon-close {
         font-size: 28px;
         top: 4px;
         right: 10px;
         color: #b20000;
       }
    }

    .menu-icon {
      margin-left: 15px;
      font-size: 28px;
      position: relative;
      top: 3px;
    }

    .menu-char {
      margin-left: 15px;
      font-size: 28px;
      position: relative;
      top: -1px;
    }



    .menu-image {
      margin-left: 15px;
      padding-top: 7px;
      padding-bottom: 3px;
      opacity: 0.4;
    }

    .menu-image-highlight {
      opacity: 1 !important;
    }

    &.active {
      .menu-image {
        opacity: 1 !important;
      }
    }

    .menu-image-label {
      top: 4px !important;
    }


    .menu-label {
      font-size: 14px;
      margin-right: 20px;

      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 1.8em;
      line-height: 1.8em;

      display: inline-flex;

      max-width: 100px;
      top: -5px;
      position: relative;

    }

    .menu-icon-close {
      position: relative;
      float: right;
      font-size: 20px;
      top: 10px;
      right: 14px;
    }

  }
}
