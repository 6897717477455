

.NewsPage {





  input[type="text"]::placeholder {
      /* Firefox, Chrome, Opera */
      text-align: center;
  }
  input[type="text"]:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      text-align: center;
  }

  input[type="text"] {
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid #e8e8e8 !important;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    position: relative;
    top: -5px;
  }

  input[type="text"]:focus {
      border: 2px solid #5686a9 !important;
  }

  input[type="text"]:hover {
      border: 2px solid #5686a9 !important;
  }

  .Brand {
    font-size: 24px;

    margin-left: 15px;
    color:  #5686a9;
    margin-bottom: 20px;
    margin-right: 40px;

    width: 180px;

    border-bottom: 1px solid #5686a9;

    font-weight: 600;

    cursor: pointer;

    .Offline {
      display: table-cell;
    }

    .Dot {
      position: relative;
      top: 4px;
      display: table-cell;
      text-align: right;
      width: 33%;
      padding-right: 3px;
    }

    .News {
      display: table-cell;
    }
  }


  .SiteDetails {
    background-color: #fff;
    padding: 20px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    float: right;
    bottom: 0px;
    right: 0px;
    width: 220px;
    margin: 0px;
    font-size: 12px;
    color: #777;

    .Copyright {
      padding-top: 10px;
      font-weight: 600;
    }

    .Credits, .Contact {
      a {
        color: #5686a9;
      }
      a:hover {
        color: #5686a9;
      }
      a:visited {
        color: #5686a9;
      }
      a:active {
        color: #5686a9;
      }
    }

  }

  .Weather {
    width: 100%;
    height: 98px;
    position: -webkit-sticky; /* Safari */
    position: sticky;

    padding-bottom: 98px;
  }

  .WeatherAddress {
    width: 200px;
    right: 20px;
    float: right;
    margin-right: 0px;
    margin-top: 125px;
    position: absolute;
  }

  .Trending {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    width: 220px;
    top: 120px;
    margin-right: 0px;
    float: right;

    color:  #5686a9;

    h6 {
      padding-left: 15px;
    }

    ul {
      list-style-type: none;
      padding-left: 30px;
    }

    li.Term {
      letter-spacing: .5px;
      font-size: 14px;
      cursor:pointer;
      color: #666;

      margin-top: 4px;

      &.active {
        color: #5686a9;
      }

      &:hover {
         color: #5686a9;
         font-weight: bold;
         letter-spacing: 0px;
      }
    }
  }

  .SearchNews {
    width: 140px;
    height: 33px;
    padding: 0;
    margin: 0;

  }

  ul.NewsContentSmall {
    padding-top: 10px;

    list-style-type: none;
    padding-left: 0px;

    top: 0px;
    margin-left: 0px;
    margin-right: 0px;

    .SectionContainer {
      display: flex;
    }

    .SectionHeader {
      margin: 20px;
      width: 100%;
      text-align: right;
    }

    .SectionExplore {
      color: #666;
      font-size: 25px;
    }

    .SectionAll {
      color: #5686a9;
      font-size: 25px;
    }

    .SectionTitle {
      color: #5686a9;
      cursor: pointer;
      font-size: 25px;
    }

    .SectionTitle:hover {
       text-decoration: underline;
       text-decoration-color: #5686a9;
       text-shadow: 0px 0px .4px #5686a9;
    }

  }

  ul.NewsContent {
    padding-top: 10px;
    list-style-type: none;
    padding-left: 0px;

    top: -570px;
    position: relative;
    margin-left: 220px;
    margin-right: 220px;



    .SectionContainer {
      display: flex;
    }

    .SectionHeader {
      margin: 20px;
      width: 100%;
      text-align: right;
    }

    .SectionExplore {
      color: #666;
      font-size: 25px;
    }

    .SectionAll {
      color: #5686a9;
      font-size: 25px;
    }

    .SectionTitle {
      color: #5686a9;
      cursor: pointer;
      font-size: 25px;
    }

    .SectionTitle:hover {
       text-decoration: underline;
       text-decoration-color: #5686a9;
       text-shadow: 0px 0px .4px #5686a9;
    }
  }
}
