.ArticleHeadline {
  &:hover {
    .ArticleAction2 {
      color: #5686a9;
    }

    .ArticleAction2:hover {
       cursor: pointer;
       text-decoration: underline;
       text-decoration-color: #5686a9;
       text-shadow: 0px 0px .2px #5686a9;
    }

  }


}

.ArticleTitle2 {
  color: #5686a9;
  font-weight: 500;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.5em;
  line-height: 1.5em;
  width: 90%;
  display: inline-block;
  white-space: nowrap;
}

.Publisher2 {
  color: gray;
  font-weight: 300;
}

.PublishedDate2 {
  color: gray;
  font-weight: 400;
}
