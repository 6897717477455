li.ArticlePreview {
  border-radius: 10px ;
  margin-bottom: 2em;
  margin-left: 2%;
  margin-right: 2%;

  -webkit-box-shadow: 3px 3px 5px 6px #f4f4f4;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #f4f4f4;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px #f4f4f4;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */

  .Tag {
    color: #aaa;
  }

  .Stream {
    color: #aaa;
  }


  &:hover, .NewsContentSmall & {
    -webkit-box-shadow: 3px 3px 5px 6px #ddd;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 3px 5px 6px #ddd;  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 3px 5px 6px #ddd;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */

    .ArticleAction {
      color: #5686a9;
      text-shadow: 0px 0px .4px #5686a9;
    }

    .ArticleAction:hover {
       cursor: pointer;
       text-decoration: underline;
       text-decoration-color: #5686a9;
    }

  }

p.ArticleTitleContainer {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: justify;
  text-justify: distribute;
  margin-bottom: 10px;

  img.NotSmall {
    vertical-align: top;
    position: relative;
    top: 14px;
  }
}

.ArticleTitle {
  color: #5686a9;
  font-weight: 400;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.8em;
  line-height: 2.8em;
  height: 2.8em;
  width: calc(100% - 40px);;
  display: inline-block;
  white-space: nowrap;
}

.ArticleTitleSmall {
  color: #5686a9;
  font-weight: 400;
  word-wrap: break-word;
}

.MainImageParent {
  border-radius: 10px !important;
  display: inline-block;
  max-width: 250px;
  max-height: 200px;
  margin-left: 20px;
  overflow: hidden;
  float: right;
}

.MainImageParentSmall {
  border-radius: 10px !important;
  display: inline-block;
  max-height: 200px;
  overflow: hidden;
  max-width: 100%;
  margin-left: 0px;
}

.MainImage {
  max-width: 100%;
}

.Publisher {
  color: darkgray;
  font-weight: 400;
  font-size: 16px;
}

.PublishedDate {
  color: darkgray;
  font-weight: 600;
  font-size: 13px;
}


.ArticleText {
  color: #666;

  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 1.8em;

  overflow: hidden;
  text-align: justify;
  text-justify: inter-word;

  padding-top: 0px;
}

.border-info {
  border: 0px solid white !important;
  border-radius: 10px;
}

.card-header {
  border-radius: 10px;
  background-color: white;
  border-bottom: 1px solid #f6f6f6;
}

.card-footer {
  border-radius: 10px;
  background-color: white;
  border-top  : 1px solid #f6f6f6;
}


}
